import { useUser } from 'components/hooks/useUser';
import { useNavigate } from 'react-router-dom';

export const UpgradeNow = () => {
  const navigate = useNavigate();
  const { isFreeUser, isTrialUser, dayLeftForTrial } = useUser();

  return (
    (isFreeUser || isTrialUser) && (
      <div className="flex flex-col sm:flex-row items-center justify-between sm:justify-start mr-3">
        <div className="h-[32px] px-4 bg-gradient-to-br from-zinc-700 to-zinc-900 rounded-md border border-purple-500 border-opacity-500 items-center gap-4 inline-flex">
          {isTrialUser && (
            <div className="text-neutral-200 text-xs font-medium font-['Inter']">
              Your trial ends in {dayLeftForTrial} days
            </div>
          )}
          <div
            className="text-right text-indigo-300 text-xs font-medium font-['Inter'] cursor-pointer"
            onClick={() => navigate('/plans')}
          >
            Upgrade now
          </div>
        </div>
      </div>
    )
  );
};
