/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   center: align the card in the center (boolean, optional)
 *   children: children to render (component(s), required)
 *   last: remove bottom margin on last card (boolean, optional)
 *   loading: toggle the loading animation (boolean, optional)
 *   noPadding: remove the padding (boolean, optional)
 *   restrictWidth: restrict the width of the card on large screens (boolean, optional)
 *   shadow: apply a box shadow (boolean, optional)
 *   title: title (string, optional)
 *   transparent: remove background colour (boolean, optional)
 *
 **********/

import { Loader, ClassHelper } from 'components/lib';
import Style from './card.tailwind.js';

export function Card(props) {
  const cardStyle = ClassHelper(Style, {
    card: true,
    shadow: props.shadow,
    center: props.center,
    noPadding: props.noPadding,
    loading: props.loading,
    className: props.className,
    last: props.last,
    restrictWidth: props.restrictWidth,
    transparent: props.transparent,
  });

  return (
    <section className={cardStyle + ' ' + Style[props.styleClass]}>
      {props.title && (
        <header className={Style.header}>
          <h1 className={Style.title}>{props.title}</h1>
        </header>
      )}

      {props.loading ? <Loader center /> : props.children}
    </section>
  );
}
