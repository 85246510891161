import Bitbucket from 'assets/Bitbucket.svg';
import Github from 'assets/Github.svg';
import Gitlab from 'assets/Gitlab.svg';
import { Button, Icon, Loader, Tooltip } from 'components/lib';
import {
  bitbucketLoginURL,
  githubLoginURL,
  gitlabLoginURL,
} from 'views/auth/prAgent/connectAccounts';
import { PrAgentStep } from './prAgentStep';
import { useUser } from 'components/hooks/useUser';
import { events, mixpanelService } from 'app/analytics';

export const getProviderDetails = (provider) => {
  const gitProvidersConnections = {
    github: {
      icon: Github,
      action: async () => {
        mixpanelService.trackEvent(events.CONNECT_GITHUB_TO_PR_AGENT_PRO_CLICK);
        const url = await githubLoginURL();
        window.location.href = url;
      },
    },
    bitbucket: {
      icon: Bitbucket,
      action: async () => {
        mixpanelService.trackEvent(
          events.CONNECT_BITBUCKET_TO_PR_AGENT_PRO_CLICK,
        );
        const url = await bitbucketLoginURL();
        window.location.href = url;
      },
    },
    gitlab: {
      icon: Gitlab,
      action: async () => {
        mixpanelService.trackEvent(events.CONNECT_GITLAB_TO_PR_AGENT_PRO_CLICK);
        const url = await gitlabLoginURL();
        window.location.href = url;
      },
    },
  };

  return gitProvidersConnections[provider];
};

const ConnectButton = ({ icon, action }) => (
  <Button
    image={icon}
    imageSize={20}
    text={<span className="text-indigo-300 text-sm md:text-base">Connect</span>}
    className="bg-transparent flex items-center px-4 m-2 rounded shadow-lg text-stone-300 outline-indigo-300 border border-indigo-300 gap-2 min-w-fit"
    action={action}
  />
);

export const GitProviderButton = ({
  icon,
  user,
  userNameField,
  handleDisconnect,
  isLoading,
}) => (
  <div
    className={`flex bg-zinc-700 items-center m-2 px-4 py-2 rounded shadow-lg text-stone-300 border-zinc-700 border gap-2 min-w-fit`}
  >
    {isLoading ? (
      <Loader />
    ) : (
      <>
        <img className={'w-5'} alt={'connection-button'} src={icon}></img>
        <span className={'text-sm md:text-base'}>
          {user[userNameField] || 'Connected'}
        </span>
        <Tooltip content={'Disconnect'}>
          <button onClick={handleDisconnect}>
            <Icon image={'trash'} />
          </button>
        </Tooltip>
      </>
    )}
  </div>
);

export const ConnectSection = () => {
  const providers = ['github', 'bitbucket', 'gitlab'];
  const { user, disconnectGitProviderMutation } = useUser();

  const getProvidersButtons = () =>
    providers.map((provider) => {
      const { icon, action } = getProviderDetails(provider);
      const userIdField = `${provider}_user_id`;
      const userNameField = `${provider}_user_name`;
      return !!user && user[userIdField] ? (
        <GitProviderButton
          key={userNameField}
          icon={icon}
          user={user}
          userNameField={userNameField}
          isLoading={disconnectGitProviderMutation.isPending}
          handleDisconnect={() => {
            mixpanelService.trackEvent(events.DISCONNECT_GIT_PROVIDER_CLICK, {
              provider,
            });
            disconnectGitProviderMutation.mutate(provider);
          }}
        />
      ) : (
        <ConnectButton key={icon} icon={icon} action={action} />
      );
    });

  return (
    <PrAgentStep
      title="Step 1 (For all users) - Connect your account "
      subtitle="Connect your git provider account to enable the Qodo Merge, the Chrome extension, and the dashboard (for the Enterprise plan)."
    >
      <div className="flex flex-wrap">
        {!user ? <Loader /> : getProvidersButtons()}
      </div>
    </PrAgentStep>
  );
};
