export const TIER_LEVELS = {
  FREE: 1,
  TRIAL: 10,
  PAID: 20,
  ENTERPRISE: 30,
};

export const TIER_LEVEL_TO_TITLE = {
  [TIER_LEVELS.FREE]: 'Developer',
  [TIER_LEVELS.TRIAL]: 'Team',
  [TIER_LEVELS.PAID]: 'Team',
  [TIER_LEVELS.ENTERPRISE]: 'Enterprise',
};

export const PLAN_NAMES = Object.freeze({
  DEVELOPER: 'Developer',
  TEAMS: 'Teams',
  ENTERPRISE: 'Enterprise',
});
