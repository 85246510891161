const Style = {
  subnav: `relative pt-5 px-5 bg-white -ml-5 -mr-6 -mt-5 mb-5 border-solid border-purple-100 border-b-2 
  dark:bg-neutral-900 dark:border-zinc-800`,

  item: 'inline-block text-slate-600 mr-5 pb-3 dark:text-slate-200',
  item_active:
    'inline-block text-slate-600 mr-5 pb-3 border-solid border-brand-300 border-b-2 dark:text-slate-200',
};

export default Style;
