import { QodoDropdown } from '../../../components/qodo/qodoDropdown';
import { useLoadingContext } from './permissionLoadingContext';

export const OrgPermission = ({ organization, user, updateUser }) => {
  const { isLoading, setLoading } = useLoadingContext();

  const getCurrentPermission = () => {
    if (user.organization_permission === 'owner') {
      return organization?.billing_admin === user.id
        ? 'Billing-Admin'
        : 'Admin';
    }
    return 'Member';
  };

  const permission = getCurrentPermission();

  if (permission === 'Billing-Admin') {
    return <div className="text-brand1-300">{permission}</div>;
  }

  const handlePermissionChange = async (newPermission) => {
    const newDisplayPermission = newPermission === 'owner' ? 'Admin' : 'Member';

    if (permission === newDisplayPermission || isLoading(user.id)) {
      return;
    }

    setLoading(user.id, true);

    try {
      await updateUser({
        attributes: { organization_permission: newPermission },
        user_id: user.id,
      });
    } catch (error) {
      console.error(`Failed to update user ${user.id}:`, error);
    } finally {
      setLoading(user.id, false);
    }
  };

  const isLoadingCurrentUser = isLoading(user.id);

  return (
    <QodoDropdown
      onSelect={handlePermissionChange}
      triggerText={permission}
      menuItems={[
        { label: 'Member', value: 'user' },
        { label: 'Admin', value: 'owner' },
      ]}
      isLoading={isLoadingCurrentUser}
    />
  );
};
