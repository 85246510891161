import React, { createContext, useState, useContext, useEffect } from 'react';

const PermissionLoadingContext = createContext({});

export const useLoadingContext = () => {
  const context = useContext(PermissionLoadingContext);
  if (context === undefined) {
    throw new Error('useLoadingContext must be used within a LoadingProvider');
  }
  return context;
};

export const LoadingProvider = ({ children }) => {
  const [loadingStates, setLoadingStates] = useState(new Set());

  const setLoading = (userId, isLoading) => {
    setLoadingStates((prev) => {
      const next = new Set(prev);
      if (isLoading) {
        next.add(userId);
      } else {
        next.delete(userId);
      }
      return next;
    });
  };

  return (
    <PermissionLoadingContext.Provider
      value={{
        isLoading: (userId) => loadingStates.has(userId),
        setLoading,
      }}
    >
      {children}
    </PermissionLoadingContext.Provider>
  );
};
