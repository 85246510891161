import { isEmpty } from 'lodash';
import { QodoDropdown } from '../../../components/qodo/qodoDropdown';

export const Environment = ({ organization, user, updateUser }) => {
  const options = organization?.baseUrlOptions;
  if (isEmpty(options)) return null;

  const currentOption = options.find(
    (option) => option.baseUrl === user?.baseUrl,
  );

  const handleEnvironmentChange = async (newBaseUrl) => {
    if (newBaseUrl == null || newBaseUrl === '') return;

    await updateUser({
      attributes: { baseUrl: newBaseUrl },
      user_id: user.id,
    });
  };

  return (
    <QodoDropdown
      triggerText={currentOption?.name ?? user?.baseUrl}
      menuItems={options.map(({ name: label, baseUrl }) => ({
        label,
        subLabel: baseUrl,
        value: baseUrl,
      }))}
      onSelect={handleEnvironmentChange}
    />
  );
};
