import { ViewContext } from 'components/lib';
import { useContext } from 'react';
import { mixpanelService, events } from '../../app/analytics';
import { ENDPOINTS, apiService } from 'api';
import { last } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { INVITE_MEMBER_TO_ORGANIZATIONS_CLICK } from '../../app/analytics/events';
import { useOrganization } from './useOrganization';

export const useInvite = ({ teamId } = {}) => {
  const context = useContext(ViewContext);
  const { organization } = useOrganization();

  const { data: invite, refetch: refetchInvites } = useQuery({
    queryKey: ['invites'],
    queryFn: () => {
      return apiService.callApi({
        endpoint: `${ENDPOINTS.INVITES}?used=false`,
        errorEvent: events.GET_INVITES_ERROR,
      });
    },
    select: ({ invites }) => last(invites),
  });

  const inviteMembersToTeam = (callback) => {
    mixpanelService.trackEvent(events.INVITE_MEMBER_TO_TEAM_CLICK, {
      team_id: teamId,
    });
    context.modal.show(
      {
        title: 'Invite members',
        form: {
          emails: {
            name: 'emails',
            type: 'email',
            required: false,
            placeholder: 'Add one or more emails, separated by commas',
          },
          file: {
            title: 'or invite from file',
            description:
              'Please format the file with a single email per line, with no commas or other separators',
            name: 'file',
            type: 'file',
            required: false,
            max: 1,
          },
          team_id: {
            name: 'team_id',
            type: 'text',
            hidden: true,
            required: false,
            value: teamId,
          },
        },
        buttonText: 'Invite',
        text: 'Note: In paid teams, additional charges will apply for each accepted invitation.',
        url: 'platform/v2/invites',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      callback,
    );
  };

  const inviteMembersToOrg = (callback) => {
    mixpanelService.trackEvent(INVITE_MEMBER_TO_ORGANIZATIONS_CLICK, {
      organization_id: organization?.id,
    });
    context.modal.show(
      {
        title: 'Invite members',
        form: {
          emails: {
            name: 'emails',
            type: 'email',
            required: false,
            placeholder: 'Add one or more emails, separated by commas',
          },
          file: {
            title: 'or invite from file',
            description:
              'Please format the file with a single email per line, with no commas or other separators',
            name: 'file',
            type: 'file',
            required: false,
            max: 1,
          },
        },
        buttonText: 'Invite',
        url: 'platform/v2/invites/organization',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      callback,
    );
  };

  return { invite, refetchInvites, inviteMembersToTeam, inviteMembersToOrg };
};
