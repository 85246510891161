import { apiService, ENDPOINTS } from 'api';
import { events } from 'app/analytics';
import { ERROR_INVITE_USER_TO_ORG } from '../app/analytics/events';

export const resendInvite = async (user) => {
  const formData = new FormData();
  formData.append('emails', user.email);
  return await apiService.callApi({
    endpoint: ENDPOINTS.INVITE_USER_TO_ORG,
    method: 'post',
    data: formData,
    errorEvent: events.ERROR_INVITE_USER_TO_ORG,
  });
};
