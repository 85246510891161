import { Icon } from 'components/lib';

export const FeatureList = ({ features }) => (
  <ul className="flex-grow mb-4">
    {features.map((feature, index) => (
      <li key={index} className="flex items-top text-sm mb-2 gap-1">
        <span>
          <Icon image={'check'} />
        </span>
        <span>{feature}</span>
      </li>
    ))}
  </ul>
);
