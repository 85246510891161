import { PLAN_NAMES } from './../../constants';

const CURRENCY = {
  usd: '$',
  eur: '€',
  gbp: '£',
};

export const ProductPrice = ({ productName, displayPrice }) => (
  <div className="flex gap-2 items-baseline">
    {/* Hiding the price of an Enterprise product workaround */}
    {productName === PLAN_NAMES.ENTERPRISE ? (
      <span className="text-2xl font-semibold text-stone-300 mb-4">
        {`Let's talk`}
      </span>
    ) : (
      displayPrice.price >= 0 && (
        <>
          <span className="text-2xl font-semibold text-stone-300 mb-4 gap-1">
            {CURRENCY[displayPrice.currency] ?? CURRENCY['usd']}
            {displayPrice?.price ?? 0}
          </span>
          <span
            className={'text-stone-400'}
          >{`/ user per ${displayPrice.interval}`}</span>
        </>
      )
    )}
  </div>
);
