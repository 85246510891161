import mixpanel from 'mixpanel-browser';
import { PORTAL, WEBAPP } from './events';
const Settings = require('settings.json');
const env = process.env.NODE_ENV;

let mixpanelInstance = null;

export const mixpanelService = {
  init: () => {
    if (!mixpanelInstance) {
      const mixpanelToken = Settings[env].mixpanel_api_key;

      mixpanel.init(mixpanelToken, {
        debug: env === 'development',
      });
      const cachedUser = JSON.parse(localStorage.getItem('user'));
      mixpanel.identify(cachedUser?.email);
      mixpanelInstance = mixpanel;
    }
  },
  trackEvent: (eventName, eventProperties = {}, context = PORTAL) => {
    if (mixpanelInstance) {
      mixpanelInstance.track(eventName, {
        ...eventProperties,
        source: WEBAPP,
        context,
      });
    } else {
      console.warn('Mixpanel has not been initialized');
    }
  },
  trackError: (eventName, eventProperties = {}, context = PORTAL) => {
    if (mixpanelInstance) {
      mixpanelInstance.track(eventName, {
        ...eventProperties,
        is_error: true,
        source: WEBAPP,
        context,
      });
    } else {
      console.warn('Mixpanel has not been initialized');
    }
  },
};
