import { Button, Icon } from 'components/lib';
import React from 'react';

export const UpgradeSection = () => {
  return (
    <div className="p-4 text-stone-300 rounded-lg border border-zinc-700 bg-gradient-to-br from-[#382b4cb1] from-10%">
      <h2 className="font-semibold text-base mb-4">Upgrade to remove limits</h2>
      <div className="mt-3 flex flex-col gap-3">
        <Feature text="Unlimited PR review calls" />
        <Feature text="Zero retention Hosting and software updates" />
        <Feature text="Customized suggestions and labels" />
        <Feature text="Premium features: Deeper code analysisa and behavior analysis" />
        <Button
          goto="/plans"
          text="Upgrade to get Qodo Merge Pro"
          small
          className="w-[240px] mt-4"
        />
      </div>
    </div>
  );
};

const Feature = ({ text }) => (
  <div className="flex items-center gap-2 text-sm">
    <Icon image="check" color="#8CC57D" />
    {text}
  </div>
);
