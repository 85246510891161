import { useUser } from 'components/hooks/useUser';
import { Animate } from 'components/lib';
import { PortalNav } from 'components/nav/sub/portal';
import { AdminSection } from './adminSection';
import { ConnectSection } from './connectSection';
import { UsageSection } from './usageSection';
import { UpgradeSection } from './upgradeSection';

export const PRAgent = () => {
  const { isFreeUser } = useUser();

  return (
    <div className="flex flex-col gap-4">
      <PortalNav />
      <Animate>
        <div className="p-8 px-5 md:col-span-2 space-y-4">
          <div className="white text-2xl font-semibold">Qodo Merge</div>
          {isFreeUser && <UpgradeSection />}
          <ConnectSection />
          <AdminSection />
          <UsageSection />
        </div>
      </Animate>
    </div>
  );
};
