import * as Switch from '@radix-ui/react-switch';

export const PricingTableToggle = ({ isMonthly, setIsMonthly }) => (
  <div className="flex justify-center my-6">
    <div className={'flex items-center gap-3'}>
      <span>Monthly</span>
      <Switch.Root
        onCheckedChange={(value) => setIsMonthly(!value)}
        checked={!isMonthly}
        className="w-10 h-6 bg-brand-100 rounded-full relative cursor-pointer"
        id="airplane-mode"
      >
        <Switch.Thumb className="block w-5 h-5 bg-brand-300 rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
      </Switch.Root>
      <span>Yearly</span>
    </div>
  </div>
);
