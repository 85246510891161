import { Loader, ViewContext, usePlans } from 'components/lib';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../app/auth';
import { PricingTable } from './pricingTable';

export const Plans = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { plans, isLoading } = usePlans();

  useEffect(() => {
    const { user } = authContext;
    if (!user) {
      navigate('/signin?redirectTo=/plans');
      return;
    }
  }, []);

  if (isLoading) {
    return <Loader center />;
  }

  return <PricingTable plans={plans} />;
};
