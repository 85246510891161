/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

import LoaderSVG from './images/loader.svg';
import { ClassHelper } from 'components/lib';
import Style from './loader.tailwind.js';

export function Loader(props) {
  const loaderStyle = ClassHelper(Style, {
    base: true,
    center: props.center,
    props,
  });

  return (
    <div className={loaderStyle}>
      <img src={LoaderSVG} alt="Qodo Spinner" />
    </div>
  );
}
