import { Icon, Link } from 'components/lib';
import { PrAgentStep } from './prAgentStep';
import React from 'react';

const links = [
  {
    text: 'GitHub settings',
    url: 'https://github.com/apps/codiumai-pr-agent-pro',
  },
  {
    text: 'Bitbucket settings',
    url: 'https://bitbucket.org/site/addons/authorize?addon_key=d6df813252c37258',
  },
  {
    text: 'GitLab settings',
    url: 'https://qodo-merge-docs.qodo.ai/installation/pr_agent_pro/#install-pr-agent-pro-for-gitlab-teams-enterprise',
  },
];

export const AdminSection = () => (
  <PrAgentStep
    title="Step 2 (For admins only) - Enable each repository in settings"
    subtitle="Enable the Qodo Merge Pro and configure it inside the repository settings."
  >
    <div className="flex gap-6">
      {links.map(({ url, disabled, text }) => (
        <div className="flex gap-1 items-center" key={text}>
          <Link
            disabled={!!disabled}
            url={url}
            text={text}
            className="text-indigo-300 hover:underline font-medium"
          />
          <Icon image="external-link" color="purple" />
        </div>
      ))}
    </div>
  </PrAgentStep>
);
