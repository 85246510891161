import { Dialog } from 'components/lib';
import { Button, ViewContext } from '../../components/lib';
import { apiService } from '../../api';
import { useContext } from 'react';
import { events, mixpanelService } from '../../app/analytics';

export const RemoveUserModal = ({
  userId,
  teamId,
  organizationId,
  onSuccess,
  closeModal,
}) => {
  const context = useContext(ViewContext);

  const removeUser = async (removeFrom) => {
    if (removeFrom === 'organizations') {
      mixpanelService.trackEvent(
        events.REMOVE_MEMBER_FROM_ORGANIZATIONS_CLICK,
        {
          organization_id: organizationId,
        },
      );
    } else {
      mixpanelService.trackEvent(events.REMOVE_MEMBER_FROM_TEAM_CLICK, {
        team_id: teamId,
      });
    }

    const encodedUserId = encodeURIComponent(userId);
    try {
      let pathPrefix = removeFrom;
      if (removeFrom === 'teams') {
        pathPrefix += `/${teamId}`;
      }
      await apiService.callApi({
        endpoint: `${pathPrefix}/users/${encodedUserId}`,
        method: 'delete',
      });
      onSuccess();
      context.notification.show(
        `${userId} was removed`,
        'success',
        true,
        'toast',
        'alert-circle',
      );
    } catch (e) {
      context.notification.show(
        e?.response?.data?.detail ?? 'Failed to remove user',
        'error',
        true,
        'toast',
        'alert-circle',
      );
    } finally {
      closeModal();
    }
  };

  return (
    <Dialog title="Remove user" onClose={closeModal}>
      <>
        <div className="DialogDescription">
          Where would you like to remove the user from?
        </div>
        <div className="grid grid-cols-3 gap-2">
          <Button small text="Cancel" outline action={closeModal} />
          <Button small text="Team" action={() => removeUser('teams')} />
          <Button
            text="Organization"
            small
            action={() => removeUser('organizations')}
          />
        </div>
      </>
    </Dialog>
  );
};
