import { Button, Dialog, ViewContext } from 'components/lib';
import { useContext, useState } from 'react';
import * as Switch from '@radix-ui/react-switch';
import { useUser } from '../hooks/useUser';
import { useMutation } from '@tanstack/react-query';
import { apiService, ENDPOINTS } from '../../api';
import { DeleteUserDialog } from './DeleteUserDialog';
import { TIER_LEVELS } from '../../constants';
import { Tooltip } from '../tooltip/tooltip';

export const UserSettingsDialog = ({ closeModal }) => {
  const context = useContext(ViewContext);
  const { user, refetchUser } = useUser();

  const [isDataUtilizationChecked, setIsDataUtilizationChecked] = useState(
    !(user.optout ?? false),
  );
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);

  const canChangeDataCollection = user?.tier_level < TIER_LEVELS.PAID;

  const { mutateAsync: updateUser } = useMutation({
    mutationFn: async (data) => {
      try {
        await apiService.callApi({
          endpoint: ENDPOINTS.USERS,
          method: 'patch',
          data,
        });
      } catch (e) {
        context.notification.show(
          e?.response?.data?.detail ?? 'Failed to update user',
          'error',
          true,
          'toast',
          'alert-circle',
        );
      }
    },
    onSuccess: () => {
      refetchUser();
      closeModal();
    },
  });

  const ShowSwitchComponent = () => (
    <Switch.Root
      onCheckedChange={() =>
        setIsDataUtilizationChecked(!isDataUtilizationChecked)
      }
      checked={isDataUtilizationChecked}
      disabled={!canChangeDataCollection}
      className="relative inline-flex items-center h-6 w-11 rounded-full bg-gray-400 transition-colors duration-200 ease-in-out data-[state=checked]:bg-violet-500 data-[disabled]:data-[state=checked]:bg-gray-400"
    >
      <Switch.Thumb className="inline-block h-5 w-5 bg-white rounded-full shadow transform transition-transform duration-200 ease-in-out translate-x-0.5 data-[state=checked]:translate-x-5" />
    </Switch.Root>
  );

  return (
    <>
      <Dialog title="Account Settings" onClose={closeModal}>
        <div className="flex flex-col gap-8 w-[450px]">
          <div className="flex flex-col text-neutral-400 gap-2">
            <div className="font-semibold text-base">Data utilization</div>
            <div className="flex gap-2">
              {!canChangeDataCollection ? (
                <Tooltip
                  content={
                    'Our paid plan users are opt out by default and can not change this settings'
                  }
                  side="top"
                >
                  <div>{ShowSwitchComponent()}</div>
                </Tooltip>
              ) : (
                <div>{ShowSwitchComponent()}</div>
              )}
              <div className="text-sm">
                <span className="font-bold">Help us improve! </span>
                <span>
                  Opting in helps us investigate issues and enhance our models.
                </span>
              </div>
            </div>
          </div>
          <div className="flex px-4 py-3 bg-zinc-700 rounded-md flex-col gap-2 text-neutral-400">
            <div className="text-base font-semibold">Delete my account</div>
            <div className="text-sm font-normal">
              All configurations and subscriptions will be deleted or cancelled
              upon account deletion.
            </div>
            <Button
              small
              text="Delete my account"
              action={() => {
                setIsDeleteUserDialogOpen(true);
              }}
              className="w-fit"
            />
          </div>
          {canChangeDataCollection && (
            <div className="flex justify-end gap-3">
              <Button small text="Cancel" outline action={closeModal} />
              <Button
                small
                text="Save"
                action={() =>
                  updateUser({
                    attributes: { optout: !isDataUtilizationChecked },
                    user_id: user.id,
                  })
                }
              />
            </div>
          )}
          {!canChangeDataCollection && (
            <div className="flex justify-end gap-3">
              <Button small text="Cancel" action={closeModal} />
            </div>
          )}
        </div>
      </Dialog>
      {isDeleteUserDialogOpen && (
        <DeleteUserDialog closeModal={() => setIsDeleteUserDialogOpen(false)} />
      )}
    </>
  );
};
