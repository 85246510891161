import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import {
  sendSignInLinkURI,
  getCustomToken,
  githubLoginURL,
  googleLoginURL,
  ssoURL,
} from './signInWithProviders';
import { mixpanelService, events } from 'app/analytics';
import Axios from 'axios';

const Settings = require('settings.json');
const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;

const firebaseConfig = Settings[env].firebase_config;
const app = initializeApp(firebaseConfig);

const auth = getAuth();

export const tempEmailLocalStorageKey = 'emailForSignIn';

export const sendSignInLink = async (email) => {
  const uri = sendSignInLinkURI();
  await Axios.get(uri, {
    params: { email: email },
    timeout: 5000,
  });
};

export const signInWithGoogle = async () => {
  mixpanelService.trackEvent(events.LOGIN_WITH_GOOGLE_CLICK);
  window.location.href = googleLoginURL();
};

export const signInWithGithub = async () => {
  mixpanelService.trackEvent(events.LOGIN_WITH_GITHUB_CLICK);
  window.location.href = githubLoginURL();
};

export const signInWithSSO = async (email, ssoOrganizationId) => {
  mixpanelService.trackEvent(events.LOGIN_WITH_SSO);
  window.location.href = ssoURL(email, ssoOrganizationId);
};

export const signInToFirebaseWithCustomToken = async (customToken) => {
  const result = await signInWithCustomToken(auth, customToken);
};

export const signInToFirebaseWithIdToken = async (IdToken) => {
  const customToken = await getCustomToken(IdToken);
  const result = await signInWithCustomToken(auth, customToken);
};
