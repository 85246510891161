import Axios from 'axios';

const Settings = require('settings.json');
const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;
console.log('🚀 ~ file: signInWithProviders.js:10 ~ env:', env);

const GOOGLE_REDIRECT_URI = `v1/auth/google-login/Codium.codium`;
const GITHUB_REDIRECT_URI = `v1/auth/github-login/portal/Codium.codium`;
const SSO_REDIRECT_URI = `v1/auth/sso-redirect/portal/Codium.codium`;
const SEND_EMAIL_SIGN_IN_LINK_URI = `v1/auth/send-sign-in-link-email/portal/Codium.codium`;

const codiumRedirectPath = Settings[env].codium_redirect_path;
const codiumGithubRedirectPath = Settings[env].codium_github_redirect_path;

const githubClientId = Settings[env].github_client_id;
const githubAuthorizeURL = 'https://github.com/login/oauth/authorize';

const googleClientId = Settings[env].google_client_id;
const googleAuthorizeURL = 'https://accounts.google.com/o/oauth2/auth';

const thisURL = encodeURIComponent(
  window.location.origin + window.location.pathname,
);

export const githubLoginURL = () => {
  const state = btoa(`${thisURL}`);
  const redirectURI = `${codiumGithubRedirectPath}/${GITHUB_REDIRECT_URI}/${state}`;
  const scope = 'read:user,user:email';

  const url = new URL(githubAuthorizeURL);
  url.searchParams.append('client_id', githubClientId);
  url.searchParams.append('redirect_uri', redirectURI);
  url.searchParams.append('scope', scope);

  return url.toString();
};

export const googleLoginURL = () => {
  const redirectURI = `${codiumRedirectPath}/${GOOGLE_REDIRECT_URI}`;
  const scope = 'email profile openid';
  const response_type = 'code';
  const access_type = 'offline';
  const state = btoa(`${thisURL};portal`);

  let url = new URL(googleAuthorizeURL);
  url.searchParams.append('client_id', googleClientId);
  // Do not use searchParams.append for redirect_uri, append it manually
  url.searchParams.append('scope', scope);
  url.searchParams.append('response_type', response_type);
  url.searchParams.append('access_type', access_type);
  url.searchParams.append('state', state);

  // Manually append redirect_uri without encoding
  return url.toString() + `&redirect_uri=${redirectURI}`;
};

export const ssoURL = (email, organizationId) => {
  const state = btoa(`${thisURL}`);
  const redirectURI = `${codiumRedirectPath}/${SSO_REDIRECT_URI}/${state}`;

  let url = new URL(redirectURI);
  url.searchParams.append('email', email);
  url.searchParams.append('org_id', organizationId);

  return url.toString();
};

export const sendSignInLinkURI = () => {
  const state = btoa(`${thisURL}`);
  return `${SEND_EMAIL_SIGN_IN_LINK_URI}/${state}`;
};

export const getCustomToken = async (idToken) => {
  const response = await Axios.post(
    'v1/auth/create_custom_token',
    { id_token: idToken },
    { timeout: 8000 },
  );
  try {
    const { id_token } = response.data;
    return id_token;
  } catch (error) {
    return null;
  }
};
