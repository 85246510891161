import { AuthContext } from 'app/auth';
import { Loader } from 'components/lib';
import React, { useContext, useEffect } from 'react';

export function Signout() {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.signout();
  }, []);

  return <Loader center />;
}
