import { useContext, useEffect, useState } from 'react';
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ViewContext, useInvite } from 'components/lib';
import { useTeam } from '../../../components/hooks/useTeam';
import { useOrganization } from '../../../components/hooks/useOrganization';
import { apiService, ENDPOINTS } from '../../../api';
import { events, mixpanelService } from '../../../app/analytics';

const PAGE_SIZE = 10000;

const fetchTeams = async () => {
  return await apiService.callApi({
    endpoint: ENDPOINTS.ORG_TEAMS,
    errorEvent: events.GET_ORG_TEAMS_ERROR,
  });
};

export const getOrganizationMembers = async (
  lastUserId = null,
  lastInviteId = null,
  pageSize = PAGE_SIZE,
) => {
  try {
    const params = new URLSearchParams();
    if (lastUserId) params.append('last_user_id', lastUserId);
    if (lastInviteId) params.append('last_invite_id', lastInviteId);
    params.append('page_size', pageSize.toString());
    return await apiService.callApi({
      endpoint: ENDPOINTS.ORG_MEMBERS,
      errorEvent: events.GET_ORG_MEMBERS_ERROR,
      params,
    });
  } catch (err) {
    return err;
  }
};

export const useOrganizationData = () => {
  const context = useContext(ViewContext);
  const { organization, handleCreateStripeSession } = useOrganization();
  const { addTeam } = useTeam();
  const { inviteMembersToOrg } = useInvite();
  const [hasMoreData, setHasMoreData] = useState(true);
  const queryClient = useQueryClient();

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
    queryKey: ['orgMembers'],
    queryFn: async ({ pageParam }) => {
      const fetchedData = await getOrganizationMembers(
        pageParam?.lastUserId,
        pageParam?.lastInviteId,
        PAGE_SIZE,
      );
      return fetchedData;
    },
    getNextPageParam: (_lastPage, pages) => {
      let lastUserId;
      let lastInviteId;

      const users = pages.flatMap((page) => page?.users ?? []);
      if (users && users.length > 0) {
        const lastUser = users.at(users.length - 1);
        lastUserId = lastUser.id;
      }

      const invited = pages.flatMap((page) => page?.invites ?? []);
      if (invited && invited.length > 0) {
        const lastInvites = invited.at(invited.length - 1);
        lastInviteId = lastInvites.id;
      }

      return {
        lastUserId,
        lastInviteId,
      };
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  const invalidateOrgMembers = () => {
    queryClient.invalidateQueries(['orgMembers']);
  };

  const { mutateAsync: updateUser } = useMutation({
    mutationFn: async (data) => {
      return apiService.callApi({
        endpoint: `${ENDPOINTS.USERS}`,
        method: 'patch',
        data,
      });
    },
    onSuccess: invalidateOrgMembers,
  });

  const removeUser = (user) => {
    mixpanelService.trackEvent(events.REMOVE_MEMBER_FROM_ORGANIZATIONS_CLICK, {
      organization_id: organization?.id,
    });
    const encodedUserId = encodeURIComponent(user.id);
    context.modal.show(
      {
        title: 'Remove User',
        form: {},
        buttonText: 'Remove User',
        text: `Are you sure you want to remove ${user.name}?`,
        url: `/platform/v2/organizations/users/${encodedUserId}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(user.name + ' was deleted', 'success', true);
        invalidateOrgMembers();
      },
    );
  };

  const { data: teamsData } = useQuery({
    queryKey: ['teams'],
    queryFn: fetchTeams,
  });

  const teams = teamsData?.teams || [];

  useEffect(() => {
    const lastPage = data?.pages?.at(data?.pages?.length - 1);
    if (lastPage && (lastPage.users === null || lastPage.invites === null)) {
      setHasMoreData(false);
    }
  }, [data]);

  return {
    organization,
    handleCreateStripeSession,
    addTeam,
    inviteMembersToOrg,
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    updateUser,
    removeUser,
    teams,
    hasMoreData,
  };
};
