import { useState } from 'react';
import { PlanCard } from './planCard';
import { PricingTableToggle } from './pricingTableToggle';

export const PricingTable = ({ plans = [], withTitle = true }) => {
  const [isMonthly, setIsMonthly] = useState(true);

  const renderPricingTableTitle = () => (
    <h2 className={'text-3xl text-center text-stone-300 mb-8'}>
      Select the right plan for your team
    </h2>
  );

  const renderEmptyPlans = () => (
    <div className={'flex justify-center'}>
      <span>No plans found</span>
    </div>
  );

  return (
    <div className={'container mx-auto px-4 py-8'}>
      {withTitle && renderPricingTableTitle()}
      <PricingTableToggle isMonthly={isMonthly} setIsMonthly={setIsMonthly} />
      {plans.length > 0 ? (
        <div className={'flex flex-col md:flex-row justify-around'}>
          {plans.map((plan) => (
            <PlanCard key={plan.name} isMonthly={isMonthly} {...plan} />
          ))}
        </div>
      ) : (
        renderEmptyPlans()
      )}
    </div>
  );
};
