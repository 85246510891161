import { useOrganization } from 'components/hooks/useOrganization';
import { Animate } from 'components/lib';
import { PortalNav } from 'components/nav/sub/portal';

export const Dashboard = () => {
  const { organization } = useOrganization();
  const mixpanel = organization?.usage_dashboard_url;
  return (
    <div className="flex flex-col gap-3 md:gap-6 h-auto">
      <PortalNav />
      <Animate>
        <div className="relative flex justify-center">
          <div className="absolute top-0 w-full h-20 bg-slate-100 dark:bg-neutral-900 flex flex-row align-baseline justify-between px-4">
            <div className="white text-2xl font-semibold">Dashboard</div>
            <div className="text-gray-400 text-sm">
              Usage stats are only visible to organization admins
            </div>
          </div>
          <iframe src={mixpanel} title="mixpanel" width="95%" height="1000px" />
          <div className="absolute bottom-0 w-full h-16 dark:bg-neutral-900 block" />
        </div>
      </Animate>
    </div>
  );
};
