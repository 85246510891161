import * as RadixDialog from '@radix-ui/react-dialog';
import './dialog.scss';
import { Button } from '../button/button';
import { useEffect } from 'react';

/***
 *
 *   DIALOG
 *
 *   PROPS
 *   title: title (string, optional)
 *   children: children to render (component(s), required)
 *   onClose: function that control the open state, required
 *
 **********/

export function Dialog({ title, onClose, children }) {
  // This is a hack to solve known issue with radix + stripe in current version. see: https://github.com/radix-ui/primitives/issues/2122
  useEffect(() => {
    return () => setTimeout(() => (document.body.style.pointerEvents = ''), 0);
  }, []);

  return (
    <RadixDialog.Root open>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="DialogOverlay" />
        <RadixDialog.Content className="DialogContent">
          {title && (
            <RadixDialog.Title className="DialogTitle">
              {title}
            </RadixDialog.Title>
          )}
          {children}
          <RadixDialog.Close asChild>
            <Button
              icon="x"
              color="dark"
              position="absolute"
              className="absolute top-4 right-4 opacity-80 ease-in-out duration-300 hover:opacity-100"
              size={24}
              action={onClose}
            />
          </RadixDialog.Close>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
