/***
 *
 *   HEADER
 *   Header section with main title
 *
 *   PROPS
 *   children: children to render (component(s), optional)
 *   title: title of the view (string, optional)
 *
 **********/
import { Logo } from 'components/lib';
import Style from './header.tailwind.js';

export function Header(props) {
  return (
    <header className={Style.header}>
      <Logo className="mx-0" />
      {props.children}
    </header>
  );
}
