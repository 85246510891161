import { useMutation, useQuery } from '@tanstack/react-query';
import { ENDPOINTS } from 'api';
import { getTeam, updateTeam } from 'api/teams';
import { ViewContext } from 'components/lib';
import { useCallback, useContext } from 'react';
import { openUrl } from 'utils';
import { events, mixpanelService } from '../../app/analytics';
import useApiCall from './useApiCall';
import { TIER_LEVELS } from '../../constants';
import { isEmpty } from 'lodash';

export const useTeam = (id) => {
  const context = useContext(ViewContext);
  const { makeApiCall, isLoading } = useApiCall();

  const { data: team, refetch: refetchTeam } = useQuery({
    queryKey: ['team', id],
    queryFn: async () => {
      const data = await getTeam(id);
      return data?.team;
    },
    enabled: id !== undefined,
  });

  const updateTeamMutation = useMutation({
    mutationFn: (data) => updateTeam(team.id, data),
  });

  const handleCreateStripeSession = useCallback(
    async ({ isManage = false, priceId = '' }) => {
      mixpanelService.trackEvent(events.MANAGE_STRIPE_TEAM_CLICK);
      const endpoint = isManage
        ? ENDPOINTS.TEAMS_CREATE_CUSTOMER_PORTAL_SESSION
        : ENDPOINTS.CREATE_CHECKOUT_SESSION;
      const requestData = {
        redirect_url: window.location.origin,
        ...(!!priceId && { price_id: priceId }),
      };

      try {
        const data = await makeApiCall(endpoint, 'post', requestData);
        if (data.portal_url) openUrl(data.portal_url);
      } catch (err) {
        context?.current && context.current.handleError(err);
      }
    },
    [makeApiCall],
  );

  const addTeam = ({ adminOptions, callback }) => {
    mixpanelService.trackEvent(events.CREATE_TEAM_CLICK);
    context.modal.show(
      {
        title: 'Create Team',
        form: {
          team_name: {
            label: 'Team Name',
            type: 'text',
            required: true,
          },
          admin: {
            label: 'Team Admin',
            type: isEmpty(adminOptions) ? 'hidden' : 'select',
            options: adminOptions,
          },
        },
        buttonText: 'Submit',
        url: '/platform/v2/teams',
        method: 'POST',
      },
      (req, res) => {
        callback && callback(res);
      },
    );
  };

  const closeTeam = ({ callback }) => {
    mixpanelService.trackEvent(events.CLOSE_TEAM_CLICK);

    const baseQuestion = 'Are you sure you want to close this team?';
    let additionalInfo = '';

    if (
      team.tier_level === TIER_LEVELS.FREE ||
      team.tier_level === TIER_LEVELS.ENTERPRISE
    ) {
      additionalInfo = 'Doing so will remove current members.';
    } else if (
      team.tier_level === TIER_LEVELS.TRIAL ||
      team.tier_level === TIER_LEVELS.PAID
    ) {
      additionalInfo =
        'Doing so will remove current members and cancel existing subscription.';
    }

    context.modal.show(
      {
        title: 'Close Team',
        text: (
          <>
            <span className="font-semibold text-base">{baseQuestion}</span>
            {additionalInfo && (
              <span className="text-sm font-normal block mt-2 text-gery-100">
                {additionalInfo}
              </span>
            )}
          </>
        ),
        form: {},
        buttonText: 'Close team',
        url: `/platform/v2/teams/${team.id}`,
        method: 'DELETE',
      },
      () => {
        callback && callback();
        refetchTeam();
      },
    );
  };

  return {
    addTeam,
    closeTeam,
    handleCreateStripeSession,
    updateTeamMutation,
    team,
    isLoading,
    refetchTeam,
  };
};
