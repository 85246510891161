import {
  AuthContext,
  Button,
  Checkbox,
  Dialog,
  ViewContext,
} from 'components/lib';
import { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { apiService, ENDPOINTS } from '../../api';

export const DeleteUserDialog = ({ closeModal }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const { mutateAsync: deleteUser } = useMutation({
    mutationFn: async () => {
      try {
        await apiService.callApi({
          endpoint: ENDPOINTS.USERS,
          method: 'delete',
        });
        authContext.signout();
      } catch (e) {
        context.notification.show(
          e?.response?.data?.detail ?? 'Failed to delete user',
          'error',
          true,
          'toast',
          'alert-circle',
        );
        closeModal();
      }
    },
  });

  return (
    <Dialog title="Delete my account" onClose={closeModal}>
      <div className="flex flex-col gap-8 w-[450px]">
        <div className="flex gap-2">
          <div>
            <Checkbox
              callback={() => setIsConfirmed(!isConfirmed)}
              checked={isConfirmed}
            />
          </div>
          <div className="text-neutral-400 text-sm">
            I confirm that by requesting to delete my account, all
            configurations and subscriptions will be deleted or cancelled.
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <Button small text="Cancel" outline action={closeModal} />
          <Button
            small
            text="Delete my account"
            action={deleteUser}
            isDisabled={!isConfirmed}
          />
        </div>
      </div>
    </Dialog>
  );
};
