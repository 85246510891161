import { Card, Image } from 'components/lib';

export const HomeActionCard = ({ action }) => {
  const {
    title,
    action: homeAction,
    subTitle,
    icon,
    className,
    iconClassName,
    isDisabled,
  } = action;
  return (
    <Card
      key={title}
      className={`flex flex-col gap-3 md:gap-4 bg-neutral-800 justify-between w-full md:w-auto md:flex-grow ${className}`}
    >
      {!!icon && (
        <div
          className={`border border-zinc-700 w-fit rounded-lg ${iconClassName}`}
        >
          <Image className={'w-6 m-2'} source={icon} />
        </div>
      )}
      <span className={'dark:text-neutral-400 text-xs md:text-sm'}>
        {title}
      </span>
      <div onClick={homeAction}>
        <span
          className={`text-violet-300 hover:cursor-pointer text-xs md:text-sm ${
            isDisabled &&
            'text-neutral-500 hover:cursor-not-allowed pointer-events-none'
          }}`}
        >
          {subTitle}
        </span>
      </div>
    </Card>
  );
};
