import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export const openUrl = (url, isNewTab = false) =>
  window.open(url, isNewTab ? '_blank' : '_self', 'noreferrer');

dayjs.extend(relativeTime);

/**
 * Converts a timestamp to a relative time string.
 * @param {string} timestampStr - The timestamp string to convert.
 * @returns {string} The relative time string.
 */
export function getRelativeTime(timestampStr) {
  const date = dayjs(timestampStr);
  if (!date.isValid()) {
    return 'Invalid date';
  }

  return date.fromNow();
}
