import { useEffect, useRef, useState } from 'react';
import { openUrl } from 'utils';
import { FeatureList } from './featureList';
import { ProductPrice } from './productPrice';
import { Button, Image } from 'components/lib';

export const PlanCard = ({
  name,
  prices,
  features,
  buttonText,
  description,
  isMonthly,
  buttonVariant,
  action,
  trialMessage,
  isActionDisabled,
  isLoading,
  isActive = false,
  dropdown = {},
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const monthlyPrice = prices.find((p) => p.interval === 'month');
  const yearlyPrice = prices.find((p) => p.interval === 'year');
  const displayPrice = isMonthly ? monthlyPrice : yearlyPrice;
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const buttonClassName =
    buttonVariant === 'primary' ? 'bg-brand-200' : 'bg-black';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderDropdownButton = (dropdown) => {
    return (
      <>
        <Button
          text={buttonText}
          fullWidth
          outline={buttonVariant === 'outline'}
          action={toggleDropdown}
          className={` ${
            isDropdownOpen ? 'rounded-t-md' : 'rounded'
          } ease-in-out text-white transition-all duration-400  ${buttonClassName}`}
        />
        {isDropdownOpen && (
          <div
            className={`absolute z-10 w-full bg-white text-stone-800 rounded-md shadow-lg transition-all duration-300 ${
              isDropdownOpen ? 'opacity-100' : 'opacity-0 invisible'
            }`}
          >
            <ul className="text-left bg-gray-700 rounded-b-md">
              {dropdown.options.map(({ name, imgUrl, url }) => (
                <li
                  key={name}
                  className="flex gap-5 items-center px-4 py-2 bg-stone-700 hover:bg-gray-600 cursor-pointer text-white"
                  onClick={() => openUrl(url)}
                >
                  <span className={'h-6 w-6'}>
                    <Image source={imgUrl} />
                  </span>
                  {name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={`relative flex flex-col gap-3 rounded-lg shadow-lg p-6 dark:bg-neutral-800 text-white m-4 md:flex-1 ${
        isActive ? 'border-2 border-brand-300' : ''
      }`}
    >
      {isActive && (
        <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-brand-300 text-white text-xs font-bold px-3 py-1 rounded-full">
          Active Plan
        </span>
      )}
      <div className={'h-24 flex flex-col gap-3'}>
        <div>
          <h3 className="text-xl text-stone-300">{name}</h3>
          <h3 className="text-sm mt-1 text-stone-400">{description}</h3>
        </div>
        {trialMessage && (
          <span className="text-sm text-stone-400">{trialMessage}</span>
        )}
      </div>
      <div className="flex flex-col h-full gap-3">
        <ProductPrice displayPrice={displayPrice} productName={name} />
        <div className={'flex flex-col justify-between h-full'}>
          <FeatureList features={features} />
          <div className="relative">
            <div ref={dropdownRef} className="relative mt-4">
              {dropdown && dropdown.show ? (
                renderDropdownButton(dropdown)
              ) : (
                <Button
                  loading={isLoading}
                  isDisabled={isActionDisabled}
                  text={buttonText}
                  outline={buttonVariant === 'outline'}
                  fullWidth
                  action={() => action(displayPrice)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
