import { useState } from 'react';
import Axios from 'axios';
import { baseRoute } from 'api';

const useApiCall = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const makeApiCall = async (
    endpoint,
    method = 'GET',
    data = null,
    config = {},
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await Axios({
        url: `${baseRoute}/${endpoint}`,
        method,
        data,
        ...config,
      });

      setIsLoading(false);
      return res?.data?.data;
    } catch (err) {
      setError(err);
      setIsLoading(false);
      throw err; // Optional: re-throw error if you want to handle it outside as well
    }
  };

  return { makeApiCall, isLoading, error };
};

export default useApiCall;
