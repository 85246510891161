import { PortalNav } from '../../components/nav/sub/portal';
import { Animate, Loader } from 'components/lib';
import { OrganizationHeader } from './componenets/organizationHeader';
import { MembersTable } from './componenets/membersTable';
import { useOrganizationData } from './hooks/useOrganizationData';
import { useState } from 'react';

export const Organization = () => {
  const {
    organization,
    handleCreateStripeSession,
    addTeam,
    inviteMembersToOrg,
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    updateUser,
    removeUser,
    teams,
    hasMoreData,
  } = useOrganizationData();
  const [searchValue, setSearchValue] = useState('');

  return (
    <div className="flex flex-col gap-4">
      <PortalNav />
      <Animate>
        {isLoading ? (
          <Loader center />
        ) : (
          <>
            <OrganizationHeader
              search={(searchValue) => setSearchValue(searchValue)}
              organization={organization}
              handleCreateStripeSession={handleCreateStripeSession}
              addTeam={addTeam}
              data={data}
              inviteMembersToOrg={inviteMembersToOrg}
            />
            <MembersTable
              data={data}
              organization={organization}
              isFetching={isFetching}
              fetchNextPage={fetchNextPage}
              updateUser={updateUser}
              removeUser={removeUser}
              teams={teams}
              hasMoreData={hasMoreData}
              searchParam={searchValue}
            />
          </>
        )}
        {isFetching && !isLoading && (
          <div className="flex justify-center py-4">
            <Loader />
          </div>
        )}
      </Animate>
    </div>
  );
};
