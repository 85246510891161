import { Pencil1Icon } from '@radix-ui/react-icons';
import { Loader } from 'components/lib';
import { useState } from 'react';

export const InlineEditableInput = ({
  initialValue = '',
  onSave,
  isLoading,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [originalValue, setOriginalValue] = useState(initialValue);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const handleBlur = () => {
    const trimmedValue = value.trim();

    if (trimmedValue && value !== originalValue) {
      // Only save if value is not empty and has changed
      onSave(trimmedValue);
      setOriginalValue(trimmedValue);
    } else if (!trimmedValue) {
      // Reset to original value if input is empty
      setValue(originalValue);
    }

    if (trimmedValue) setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    } else if (e.key === 'Escape') {
      setValue(originalValue);
      setIsEditing(false);
    }
  };

  return (
    <div className="flex items-center space-x-2 m-1 relative">
      {isEditing ? (
        <input
          disabled={isLoading}
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          maxLength={40}
          className="focus:outline focus:outline-zinc-500 rounded bg-zinc-800"
        />
      ) : (
        <div
          onClick={() => {
            if (isLoading) return;
            setIsEditing(true);
          }}
          className={`hover:cursor-pointer flex items-center gap-2`}
        >
          <span className={'font-semibold text-lg'}>{initialValue}</span>
          {isLoading ? <Loader /> : <Pencil1Icon />}
        </div>
      )}
    </div>
  );
};
