import { useMutation } from '@tanstack/react-query';
import { ENDPOINTS, apiService } from 'api';
import { useTeam } from 'components/hooks/useTeam';
import { useUser } from 'components/hooks/useUser';
import { Button, Icon, ViewContext, useInvite } from 'components/lib';
import { TIER_LEVELS } from '../../constants';
import { isEmpty } from 'lodash';
import { useContext } from 'react';

export const Invitation = () => {
  const context = useContext(ViewContext);
  const { user } = useUser();
  const teamId = user?.teams?.[0]?.team_id;

  const { team } = useTeam(teamId);
  const { invite, refetchInvites } = useInvite({ teamId });

  const { mutateAsync: responedInviteMutation, isPending } = useMutation({
    mutationFn: async (accept) => {
      return apiService.callApi({
        endpoint: `${ENDPOINTS.INVITES}/${invite.id}`,
        method: 'patch',
        data: { accept },
      });
    },
    onSuccess: (_, accept) => {
      refetchInvites();
      if (accept) {
        context.notification.show(
          <span>
            Congrats! You are now a member of <code>{invite.team_owner}</code>'s
            team
          </span>,
          'success',
          false,
        );
      }
    },
  });

  if (isEmpty(invite)) return null;

  return (
    <div className="flex flex-col items-start space-y-4 md:space-y-6 p-3 md:p-4 rounded-md self-stretch bg-brand-300">
      <div className="flex justify-between w-full">
        <span className="flex flex-row gap-2 font-medium text-sm">
          <Icon image="bell" color="#fff" size={24} />
          {team ? (
            <span>
              By accepting this invite you will be joining the team of{' '}
              <code>{invite.team_owner}</code> and will no longer be a member of{' '}
              <code>{team?.owner_user_id}</code>'s team.
            </span>
          ) : (
            <span>
              By accepting this invite you will be joining the team of{' '}
              <code>{invite.team_owner}</code>
            </span>
          )}
        </span>
      </div>
      <div className="flex flex-row gap-4">
        <Button
          loading={isPending}
          text={
            team ? (
              <span>
                Remain a member of <code>{team?.owner_user_id}</code>'s team
              </span>
            ) : (
              'Decline'
            )
          }
          small
          className="text-white border-solid border border-slate-200 hover:bg-brand-200 bg-brand-400"
          action={() => responedInviteMutation(false)}
        />
        <Button
          loading={isPending}
          text={
            team ? (
              <span>
                Become a member of <code>{invite.team_owner}</code>'s team
              </span>
            ) : (
              'Accept'
            )
          }
          small
          className="text-white border-solid border border-slate-200 hover:bg-brand-200 bg-brand-400"
          action={() =>
            team
              ? context.modal.show(
                  {
                    title: 'Confirm switching teams',
                    form: {
                      accept: {
                        type: 'hidden',
                        value: true,
                      },
                    },
                    buttonText: 'Switch teams',
                    text: (
                      <span>
                        Please confirm you want to switch teams. i.e joining{' '}
                        <code>{invite.team_owner}</code>'s team and leaving{' '}
                        <code>{team?.owner_user_id}</code>'s team.
                        {team?.owner_user_id !== user.email
                          ? ''
                          : ' Be aware that by leaving your current team, it will be closed.' +
                            (team?.tier_level !== TIER_LEVELS.PAID
                              ? ''
                              : ' Furthermore, any existing subscription will end automatically, leading to the unavailability of the advanced features for both you and your team members.')}
                      </span>
                    ),
                    url: `platform/v2/${ENDPOINTS.INVITES}/${invite.id}`,
                    method: 'PATCH',
                    destructive: false,
                  },
                  () => {
                    refetchInvites();
                    context.notification.show(
                      <span>
                        Congrats! You are now a member of{' '}
                        <code>{invite.team_owner}</code>'s team
                      </span>,
                      'success',
                      false,
                    );
                  },
                )
              : responedInviteMutation(true)
          }
        />
      </div>
    </div>
  );
};
