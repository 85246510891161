import { QodoDropdown } from 'components/qodo/qodoDropdown';
import { Icon } from '../../../components/icon/icon';
import { Tooltip } from '../../../components/tooltip/tooltip';
import { TIER_LEVELS } from '../../../constants';

const TeamPermission = ({ user, team, updateUser }) => {
  if (!team) return null;
  const permission = user.permission === 'owner' ? 'Admin' : 'Member';

  const handlePermissionChange = async (newPermission) => {
    await updateUser({
      attributes: { permission: newPermission },
      user_id: user.id,
    });
  };

  return (
    <QodoDropdown
      triggerText={permission}
      menuItems={[
        { label: 'Member', value: 'user' },
        { label: 'Admin', value: 'owner' },
      ]}
      onSelect={handlePermissionChange}
    />
  );
};

export const getTeamLabel = (team, user, updateUser) => {
  if (!team) return null;
  return (
    <div className={'flex items-center gap-1'}>
      <TeamPermission user={user} team={team} updateUser={updateUser} /> at{' '}
      <span>{team.name}</span>
      {team.tier_level === TIER_LEVELS.PAID && (
        <Tooltip
          side="top"
          content={<p className="leading-6 text-xs">Independent team</p>}
        >
          <Icon asChild image="info" color="#AEA1F1" size={14} />
        </Tooltip>
      )}
    </div>
  );
};
