import { TIER_LEVELS } from '../../../constants';
import { useOrganization } from 'components/hooks/useOrganization';
import { isEmpty } from 'lodash';

export const TeamTabLabel = ({ tierLevel = TIER_LEVELS.FREE }) => {
  const { organization } = useOrganization();

  return (
    <div className={'flex items-baseline gap-1'}>
      {(tierLevel === TIER_LEVELS.PAID || isEmpty(organization)) && (
        <span>My Team</span>
      )}
      {tierLevel !== TIER_LEVELS.PAID && organization && (
        <>
          <span>Teams</span>
          <div className={'flex items-baseline gap-1'}>
            <span>@</span>
            <span className="h-5 px-2.5 py-1 rounded border border-neutral-700 justify-start items-center gap-1 inline-flex text-xs font-semibold font-['Inter'] tracking-wide uppercase bg-gray-50 text-black">
              {organization?.name}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
