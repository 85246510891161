import { Dropdown } from '../../components/dropdown/dropdown';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS, apiService } from 'api';
import { events } from '../../app/analytics';
import { useQuery } from '@tanstack/react-query';

export const getOrganizationTeams = async () => {
  try {
    const data = await apiService.callApi({
      endpoint: ENDPOINTS.ORG_TEAMS,
      errorEvent: events.GET_ORG_TEAMS_ERROR,
    });
    return data;
  } catch (err) {
    return err;
  }
};

export const SwitchTeamDropdown = () => {
  const navigate = useNavigate();

  const { data: orgTeams } = useQuery({
    queryKey: ['orgTeams'],
    queryFn: getOrganizationTeams,
  });

  return (
    <div>
      <Dropdown>
        <>
          <Dropdown.Trigger>
            <div className="cursor-pointer">
              <ChevronDownIcon />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            <>
              {orgTeams?.teams
                ?.toSorted((a, b) => a.name.localeCompare(b.name))
                .map((option) => (
                  <Dropdown.MenuItem
                    key={option.id}
                    onSelect={() => navigate(`/team/${option.id}/users`)}
                  >
                    <div>{option.name}</div>
                  </Dropdown.MenuItem>
                ))}
            </>
          </Dropdown.Menu>
        </>
      </Dropdown>
    </div>
  );
};
