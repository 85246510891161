const Style = {
  card: `relative p-6 x-1 mb-6 bg-white rounded dark:bg-zinc-800 
    [&>h2]:font-bold [&>h2]:mt-6 [&>a]:font-semibold [&>a]:inline-block [&>a]:mt-3`,
  header:
    'relative pb-4 mb-6 border-dotted border-slate-100 border-b dark:border-slate-700',
  title: 'float:left m-0 text-lg font-semibold',
  center: 'mx-auto',
  loading: 'h-60',
  shadow: 'drop-shadow-sm',
  noPadding: '!p-0',
  restrictWidth: 'max-w-[30em]',
  last: 'mb-0',
  transparent: '!bg-transparent',
  centered: 'flex flex-col items-center',
};

export default Style;
