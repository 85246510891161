import Style from '../../../components/table/table.tailwind';
import { find, isEmpty } from 'lodash';
import { getTeamLabel } from './teamPermission';
import { OrgPermission } from './orgPermission';
import { Environment } from './enviroment';
import { Badge } from '../../../components/badge/badge';
import { Tooltip } from '../../../components/tooltip/tooltip';
import { Icon } from '../../../components/icon/icon';
import Github from '../../../assets/Github.svg';
import Bitbucket from '../../../assets/Bitbucket.svg';
import Gitlab from '../../../assets/Gitlab.svg';
import { resendInvite } from '../../../api/invites';
import { getRelativeTime } from '../../../utils';

const gitProvidersConnections = {
  github: { icon: Github },
  bitbucket: { icon: Bitbucket },
  gitlab: { icon: Gitlab },
};

export const createColumns = ({
  organization,
  updateUser,
  removeUser,
  teams,
  context,
}) => {
  const columns = [
    // {
    //   id: 'name',
    //   accessorKey: 'name',
    //   header: 'Name',
    //   cell: (info) => <div className={Style.cell}>{info.getValue()}</div>,
    // },
    {
      id: 'email',
      accessorKey: 'email',
      header: 'Email',
      cell: (info) => <div className={Style.cell}>{info.getValue()}</div>,
    },
    {
      id: 'teamPermission',
      accessorKey: 'permission',
      header: 'Team Role',
      cell: (info) => {
        const user = info.row.original;
        const team = find(teams, {
          id: user?.teams?.[0]?.team_id,
        });
        return (
          <div className={Style.cell}>
            {getTeamLabel(team, user, updateUser)}
          </div>
        );
      },
    },
    {
      id: 'orgPermission',
      accessorKey: 'organization_permission',
      header: 'Organization Role',
      cell: (info) => {
        const user = info.row.original;
        const rowType = getRowType(user);

        if (rowType === 'invite') {
          return <div className={Style.cell}>Member</div>;
        }

        return (
          <div className={Style.cell}>
            <OrgPermission
              user={user}
              updateUser={updateUser}
              organization={organization}
            />
          </div>
        );
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (info) => {
        const user = info.row.original;
        const rowType = getRowType(user);

        let status = 'unknown';
        let color = 'gray';

        if (rowType === 'user') {
          if (user.verified) {
            status = 'verified';
            color = 'green';
          } else if (user.allowed) {
            status = 'registered';
            color = 'green';
          } else {
            status = 'disabled';
            color = 'red';
          }
        } else if (rowType === 'invite') {
          status = 'invited';
          color = 'purple';
        }

        return (
          <div key={user.id + '_badge'} className={Style.cell}>
            <Badge
              text={status.charAt(0).toUpperCase() + status.slice(1)}
              color={color}
              className={Style.badge}
            />
          </div>
        );
      },
    },
    {
      id: 'providers',
      accessorKey: 'providers',
      header: 'Qodo Merge Pro',
      cell: (info) => {
        const user = info.row.original;
        const rowType = getRowType(user);
        const prAgentConnectedProviders = ['github', 'gitlab', 'bitbucket'];

        if (rowType === 'invite') return null;

        const providers = prAgentConnectedProviders
          .map((provider) => {
            const userIdField = `${provider}_user_id`;
            const userNameField = `${provider}_user_name`;
            if (user[userIdField] && user[userNameField]) {
              return (
                <Tooltip key={provider} content={`Enabled in ${provider}`}>
                  <img
                    key={provider}
                    className="w-6"
                    alt="git_provider"
                    src={gitProvidersConnections[provider].icon}
                  />
                </Tooltip>
              );
            }
            return null;
          })
          .filter(Boolean);

        return (
          <div className={Style.cell}>
            {providers?.length > 0 ? (
              <div className="flex gap-2">{providers}</div>
            ) : (
              'Not Connected'
            )}
          </div>
        );
      },
    },
  ];

  const environmentColumn = !isEmpty(organization?.baseUrlOptions)
    ? [
        {
          id: 'environment',
          accessorKey: 'environment',
          header: 'Environment',
          cell: (info) => {
            const user = info.row.original;
            const rowType = getRowType(user);

            if (rowType === 'invite') return null;

            return (
              <div className={Style.cell}>
                <Environment
                  organization={organization}
                  user={user}
                  updateUser={updateUser}
                />
              </div>
            );
          },
        },
      ]
    : [];
  const lastLogin = {
    id: 'lastLogin',
    accessorKey: 'last_login', // Assuming the data field is named 'last_login'
    header: 'Last Login',
    cell: (info) => {
      const lastLoginDate = info.getValue();
      const relativeLastLogin = getRelativeTime(lastLoginDate);
      return (
        <div className={Style.cell}>
          {lastLoginDate ? relativeLastLogin : 'Never'}
        </div>
      );
    },
  };

  const actionColumn = organization?.invite_only
    ? [
        {
          id: 'action',
          accessorKey: 'action',
          header: 'Action',
          cell: (info) => {
            const user = info.row.original;
            const rowType = getRowType(user);

            return (
              <div className={Style.row_actions}>
                <Tooltip
                  side="bottom"
                  content={<p className="leading-6 text-xs">Delete</p>}
                >
                  <button className="mr-2" onClick={() => removeUser(user)}>
                    <Icon image="trash" />
                  </button>
                </Tooltip>
                {rowType === 'invite' && (
                  <Tooltip
                    side="bottom"
                    content={<p className="leading-6 text-xs">Resend invite</p>}
                  >
                    <button
                      onClick={() => {
                        resendInvite(user);
                        context.notification.show(
                          'invitation for ' + user.name + ' was resent',
                          'success',
                          true,
                        );
                      }}
                    >
                      <Icon image="refresh-cw" />
                    </button>
                  </Tooltip>
                )}
              </div>
            );
          },
        },
      ]
    : [];

  return [...columns, ...environmentColumn, lastLogin, ...actionColumn];
};

function getRowType(row) {
  if ('used_trial' in row) {
    return 'user';
  } else if ('used' in row) {
    return 'invite';
  } else {
    return 'unknown';
  }
}
