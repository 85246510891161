const Style = {
  // base: 'inline-block mx-1 py-1 px-2.5 text-white uppercase text-xs rounded font-semibold',
  base: `h-5 px-2.5 py-1 bg-zinc-800 rounded border border-neutral-700 justify-start items-center gap-1 inline-flex  text-xs font-semibold font-['Inter'] tracking-wide bg-opacity-40 uppercase`,
  red: 'bg-red-500',
  blue: 'bg-blue-500',
  green: `text-teal-400 bg-teal-900`,
  orange: 'bg-orange-500',
  purple: 'text-indigo-400 bg-indigo-900',
};

export default Style;
