import Bitbucket from 'assets/Bitbucket.svg';
import Github from 'assets/Github.svg';
import Gitlab from 'assets/Gitlab.svg';
import { useNavigate } from 'react-router-dom';

const ConnectButton = ({ icon }) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center space-x-2 md:space-x-4 p-3 md:p-4 rounded-md dark:bg-zinc-700 border border-zinc-600 hover:cursor-pointer hover:scale-110 transition-transform"
      onClick={() => navigate('/qodo-merge')}
    >
      <img width={'24px'} src={icon} alt="qodo-merge" />
    </div>
  );
};

export const PrAgentButtons = () => {
  const providers = {
    github: Github,
    bitbucket: Bitbucket,
    gitlab: Gitlab,
  };

  return (
    <div className="flex flex-row gap-2">
      {Object.entries(providers).map(([key, value]) => (
        <ConnectButton key={key} icon={value} />
      ))}
    </div>
  );
};
