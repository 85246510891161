import { apiService, ENDPOINTS } from 'api';
import { events } from 'app/analytics';

const DEFAULT_TEAM_NAME = 'Team';

export const getTeam = async (id) => {
  try {
    return await apiService.callApi({
      endpoint: `${ENDPOINTS.TEAMS}/${id}`,
      errorEvent: events.GET_TEAM_ERROR,
    });
  } catch (err) {
    return null;
  }
};

export const createTeam = async () => {
  try {
    return await apiService.callApi({
      endpoint: ENDPOINTS.TEAMS,
      method: 'post',
      data: { team_name: DEFAULT_TEAM_NAME },
      errorEvent: events.CREATE_TEAM_ERROR,
    });
  } catch (err) {
    return null;
  }
};

export const getPlans = async () => {
  try {
    return await apiService.callApi({
      endpoint: ENDPOINTS.TEAMS_PLANS,
      errorEvent: events.GET_PLANS_ERROR,
    });
  } catch (err) {
    return null;
  }
};

export const updateTeam = async (teamId, reqData) => {
  return await apiService.callApi({
    endpoint: `${ENDPOINTS.TEAMS}/${teamId}`,
    method: 'patch',
    data: { ...reqData },
    errorEvent: events.UPDATE_TEAM_ERROR,
  });
};

export const ssoSetup = async (redirect_url) => {
  try {
    return await apiService.callApi({
      endpoint: ENDPOINTS.SSO_SETUP,
      method: 'post',
      data: { redirect_url },
      errorEvent: events.SSO_SETUP_ERROR,
    });
  } catch (e) {
    console.log(e);
  }
};
